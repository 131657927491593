import ModalTextWrap from 'components/modalTextWrap/ModalTextWrap';

function Privacy() {
  return (
    <ModalTextWrap title={'Privacy Policy'}>
      <h3>Introduction</h3>
      <p>
        Welcome to crownspin777.com! Your privacy is of paramount importance to
        us. This Privacy Policy outlines how we collect, use, protect, and share
        information gathered from our users. By accessing and using our
        services, you agree to the practices described in this policy.
      </p>

      <ol>
        <li>
          <h3>Information Collection</h3>
          <ul>
            <li>
              A. <span>Email-Based Usage:</span> To play games on
              crownspin777.com, users are only required to provide a valid
              email address. No full account creation is necessary.
            </li>
            <li>
              B. <span>Age Verification Information:</span> We may collect
              information to verify that users are 18 years of age or older.
            </li>
          </ul>
        </li>
        <li>
          <h3>Use of Information</h3>
          <ul>
            <li>
              Your email address is used to facilitate game access and to send
              occasional updates or promotional information related to
              crownspin777.com.
            </li>
          </ul>
        </li>
        <li>
          <h3>Sharing of Information</h3>
          <ul>
            <li>
              We do not sell, trade, or otherwise transfer to outside parties
              your personally identifiable information unless we provide users
              with advance notice.
            </li>
          </ul>
        </li>
        <li>
          <h3>Data Protection</h3>
          <ul>
            <li>
              We implement a variety of security measures to maintain the safety
              of your personal information.
            </li>
          </ul>
        </li>
        <li>
          <h3>Cookie Policy</h3>
          <ul>
            <li>
              A. <span>Use of Cookies:</span> crownspin777.com uses cookies to
              enhance user experience, gather general visitor information, and
              track visits to our website.
            </li>
            <li>
              B. <span>Cookie Control:</span> Users may choose to set their web
              browser to refuse cookies or to alert when cookies are being sent.
              However, doing so may affect the functionality of some parts of
              our site.
            </li>
          </ul>
        </li>
        <li>
          <h3>Compliance with Legal Age Policy</h3>
          <ul>
            <li>
              Only users who are 18 years of age or older are permitted to use
              crownspin777.com. By using our services, you warrant that you
              meet this age requirement.
            </li>
          </ul>
        </li>
        <li>
          <h3>Changes to Our Privacy Policy</h3>
          <ul>
            <li>
              Any changes to our privacy policy will be posted on this page.
              Your continued use of crownspin777.com after such changes
              constitutes your acceptance of the new privacy policy.
            </li>
          </ul>
        </li>
        <li>
          <h3>Contact Us</h3>
          <ul>
            <li>
              If you have any questions regarding this privacy policy, you may
              contact us.
            </li>
          </ul>
        </li>
        <li>
          <h3>Your Consent</h3>
          <ul>
            <li>
              By using crownspin777.com, you consent to our website’s privacy
              policy.
            </li>
          </ul>
        </li>
      </ol>

      <h4>
        We're Committed to Protecting Your Privacy and Ensuring a Safe Online
        Experience at crownspin777.com!
      </h4>
    </ModalTextWrap>
  );
}

export default Privacy;
