import React, { useState, useRef } from 'react';
import { RiArrowRightSFill } from 'react-icons/ri';
import Social from 'components/social';
import Container from 'components/container/Container';
import Terms from 'components/footerModals/Terms';
import Privacy from 'components/footerModals/Privacy';
import ContactForm from 'components/contactForm/ContactForm';
import Modal from 'components/modal/Modal';
import ButtonClose from 'components/buttonClose/ButtonClose';
import { ReactComponent as Icon18 } from 'images/18-plus-white.svg';
import styles from './Footer.module.scss';

function Footer() {
  const [activeModal, setActiveModal] = useState(null);
  const modalRef = useRef();

  const openModal = modalName => {
    setActiveModal(modalName);
    modalRef.current.open();
  };

  const closeModal = () => {
    setActiveModal(null);
    modalRef.current.close();
  };

  return (
    <>
      <footer className={styles.footer}>
        <Container>
          <div className={styles.footerContainer}>
            <div className={styles.top}>
              <div>
                <Icon18 className={styles.icon18} />
                <p>
                  Please note that our complimentary social games are designed
                  purely for fun and do not include real money gambling or
                  opportunities to win actual monetary prizes. Participation is
                  limited to individuals aged 18 and above. Remember, it's
                  essential to play responsibly and within your financial
                  limits.
                </p>
              </div>
              <div>
                <ul className={styles.footerLinks}>
                  <li>
                    <button
                      className={styles.link}
                      type="button"
                      onClick={() => openModal('terms')}
                    >
                      <RiArrowRightSFill />
                      <span>Terms & Conditions</span>
                    </button>
                  </li>
                  <li>
                    <button
                      className={styles.link}
                      type="button"
                      onClick={() => openModal('privacy')}
                    >
                      <RiArrowRightSFill />
                      <span>Privacy Policy</span>
                    </button>
                  </li>
                  <li>
                    <button
                      className={styles.link}
                      type="button"
                      onClick={() => openModal('contact')}
                    >
                      <RiArrowRightSFill />
                      <span>Contact Us</span>
                    </button>
                  </li>
                </ul>
              </div>
              <div>
                <Social />
              </div>
            </div>

            <div className={styles.bottom}>
              crownspin777.com&copy;2024 All rights reserved
            </div>
          </div>
        </Container>
      </footer>
      <Modal ref={modalRef} activeModal={activeModal}>
        <ButtonClose onClick={closeModal} />
        {activeModal === 'terms' && <Terms />}
        {activeModal === 'privacy' && <Privacy />}
        {activeModal === 'contact' && <ContactForm />}
      </Modal>
    </>
  );
}

export default Footer;
