import { Helmet } from 'react-helmet-async';

const TITLE = 'crownspin777.com';
const CONTENT =
  'crownspin777.com: Your Ultimate Hub for Free Social Gaming Fun! Join our vibrant community, play exciting free social games, and connect with friends online.';
function HelmetHead() {
  return (
    <Helmet>
      <title>{TITLE}</title>
      <meta name="description" content={CONTENT} />
    </Helmet>
  );
}

export default HelmetHead;
