import React, { useRef, useState } from 'react';
import Container from 'components/container/Container';
import FormModal from 'components/formModal/FormModal';
import ButtonClose from 'components/buttonClose/ButtonClose';
import Modal from 'components/modal/Modal';
import Section from 'components/section/Section';
import Button from 'components/button/Button';
import HeroImg from 'images/hero.webp';
import styles from './Hero.module.scss';

function Hero() {
  const modalRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Section>
      <Container>
        <h1
          className={`${styles.title} ${isButtonHovered && styles.titleFilter}`}
        >
          Your Exciting Journey Here!
        </h1>
        <div className={styles.imageWrap}>
          <img
            src={HeroImg}
            alt="Aztec Magic Bonanza"
            className={`${isButtonHovered && styles.imgOpacity}`}
          />
        </div>

        <div className={styles.btnContainer}>
          <Button
            variant="btnHero"
            title={'PLAY'}
            onClick={handleButtonClick}
            onMouseEnter={() => setIsButtonHovered(true)}
            onMouseLeave={() => setIsButtonHovered(false)}
            svg={
              <svg
                viewBox="-5 -5 110 110"
                preserveAspectRatio="none"
                aria-hidden="true"
              >
                <path d="M0,0 C0,0 100,0 100,0 C100,0 100,100 100,100 C100,100 0,100 0,100 C0,100 0,0 0,0" />
              </svg>
            }
          />
        </div>

        {isModalOpen && (
          <Modal
            ref={modalRef}
            activeModal="formmodal"
            onCloseModal={handleCloseModal}
            setIsModalOpen={setIsModalOpen}
          >
            <ButtonClose onClick={handleCloseModal} />
            <FormModal />
          </Modal>
        )}
      </Container>
    </Section>
  );
}

export default Hero;
