import ModalTextWrap from 'components/modalTextWrap/ModalTextWrap';

function Terms() {
  return (
    <ModalTextWrap title={'Terms and Conditions'}>
      <h4>Welcome to Your Gateway of Free Social Gaming</h4>

      <ol>
        <li>
          <h3>Introduction</h3>
          <ul>
            <li>
              Welcome to crownspin777.com! These Terms and Conditions are here
              to ensure a safe and enjoyable gaming experience. By using our
              website, you are agreeing to these terms.
            </li>
          </ul>
        </li>
        <li>
          <h3>Accepting the Terms</h3>
          <ul>
            <li>
              By accessing crownspin777.com, you confirm that you understand
              and agree to these Terms and Conditions.
            </li>
          </ul>
        </li>
        <li>
          <h3>Age Restriction Compliance</h3>
          <ul>
            <li>
              A. <span>Mandatory Age Limit:</span> Only individuals aged 18 or
              older are permitted to use our services.
            </li>
            <li>
              B. <span>Age Verification:</span> We reserve the right to verify
              the age of our users to enforce this policy.
            </li>
          </ul>
        </li>
        <li>
          <h3>Simplified Access</h3>
          <ul>
            <li>
              A. <span>No Account Needed:</span> Enjoy games by simply using
              your email – no account setup required!
            </li>
            <li>
              B. <span>Email Usage:</span> Your email is your key to our games
              and occasional updates from us.
            </li>
          </ul>
        </li>
        <li>
          <h3>Your Commitment</h3>
          <ul>
            <li>
              A. <span>Legal Compliance:</span> You agree to comply with all
              laws applicable to your use of our services.
            </li>
            <li>
              B. <span>Personal Use Only:</span> Our services are for your
              personal, non-commercial enjoyment.
            </li>
          </ul>
        </li>
        <li>
          <h3>Privacy Matters</h3>
          <ul>
            <li>
              We value your privacy. Please refer to our Privacy Policy for
              detailed information on how we handle your data.
            </li>
          </ul>
        </li>
        <li>
          <h3>Ownership of Content</h3>
          <ul>
            <li>
              All content on crownspin777.com is our exclusive property or that
              of our licensors. Respect our intellectual property rights.
            </li>
          </ul>
        </li>
        <li>
          <h3>Service and Liability</h3>
          <ul>
            <li>
              A. <span>Service Provision:</span> We offer our services "as is."
              Availability may vary.
            </li>
            <li>
              B. <span>Limitation of Liability:</span> We're not liable for
              losses or damages from your use of crownspin777.com
            </li>
          </ul>
        </li>
        <li>
          <h3>Changes to Terms</h3>
          <ul>
            <li>
              We may update these terms. Continued use after changes means you
              accept the new terms.
            </li>
          </ul>
        </li>
        <li>
          <h3>Get In Touch</h3>
          <ul>
            <li>For questions or feedback, contact us.</li>
          </ul>
        </li>
        <li>
          <h3>Your Agreement</h3>
          <ul>
            <li>
              By using crownspin777.com, you acknowledge your understanding and
              agreement to these Terms and Conditions.
            </li>
          </ul>
        </li>
      </ol>

      <h4>Play Responsibly and Enjoy Your Experience at crownspin777.com!</h4>
    </ModalTextWrap>
  );
}

export default Terms;
