import styles from './GameModal.module.scss';

function GameModal() {
  return (
    <iframe
      className={styles.iframe}
      title="Game"
      sandbox="allow-same-origin allow-scripts"
      allow="fullscreen; screen-wake-lock"
      src="https://bgaming-network.com/play/AztecMagicBonanza/FUN?server=demo"
    ></iframe>
  );
}

export default GameModal;
