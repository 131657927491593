import {
  RiFacebookBoxFill,
  RiTwitterXFill,
  RiInstagramFill,
  RiArrowRightSFill,
} from 'react-icons/ri';
import styles from './Social.module.scss';

function Social() {
  const socialMediaData = [
    {
      icon: <RiTwitterXFill />,
      link: 'https://www.twitter.com',
      label: 'Twitter',
    },
    {
      icon: <RiFacebookBoxFill />,
      link: 'https://www.facebook.com',
      label: 'Facebook',
    },
    {
      icon: <RiInstagramFill />,
      link: 'https://www.instagram.com/',
      label: 'Instagram',
    },
  ];

  return (
    <ul>
      {socialMediaData.map(({ icon, link, label }) => (
        <li key={label}>
          <a
            href={link}
            aria-label={label}
            target="_blank"
            rel="noreferrer"
            className={styles.social__link}
          >
            <RiArrowRightSFill />
            {icon} <span>{label}</span>
          </a>
        </li>
      ))}
    </ul>
  );
}

export default Social;
