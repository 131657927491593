import { SetCookies } from 'helpers/Cookies';
import Button from 'components/button/Button';
import styles from './CookiesModal.module.scss';

const CookiesModal = ({ showCookies }) => {
  function checkCookie() {
    showCookies(false);
    SetCookies('bookofdeadest.com', JSON.stringify(true));
  }

  return (
    <>
      <div className={styles.cookieBar}>
        <div className={styles.content}>
          <p className={styles.text}>
            Our website uses cookies to improve your online experience. By
            proceeding, you consent to our cookie policy.
          </p>
        </div>

        <Button title={'AGREE'} onClick={() => checkCookie(false)} />
      </div>
    </>
  );
};

export default CookiesModal;
