import Button from 'components/button/Button';
import styles from './AgeModal.module.scss';

function AgeModal({ onClose }) {
  const handleButtonClick = () => {
    onClose();
  };

  const handleNoButtonClick = () => {
    // Reload the page when "NO" button is clicked
    window.location.reload();
  };

  return (
    <>
      <div className={styles.text}>
        <p>
          Greetings at crownspin777.com! We'd like to remind you that our games
          are exclusively for those who are 18 years of age or older. We
          advocate for responsible play and underscore the importance of being
          over 18 to engage in our games. Play safely and enjoy! Ready?
        </p>
      </div>
      <div className={styles.buttons}>
        <Button title={'YES'} onClick={handleButtonClick}></Button>
        <Button
          variant="btnNo"
          title={'NO'}
          onClick={handleNoButtonClick}
        ></Button>
      </div>
    </>
  );
}

export default AgeModal;
